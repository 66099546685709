import { graphql, Link } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import NewsNavi from '@/components/News/NewsNavi';
import SEO from '@/components/seo';
import UILabel from '@/components/UI/UILabel';
import CDNReplace from '@/components/CDNReplace';
import hoverCircle from '@/components/Interaction/hoverCircle';
import * as Util from '@/components/Util';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import { Context } from '@/components/Layout';

import * as styles from '@/styles/page/news.module.scss';
import * as pageHeaderCategory from '@/styles/object/page-header-category.module.scss';

const NewsArchive: React.FC<NewsArchiveProps<GatsbyTypes.NewsArchiveQuery>> = ({
  pageContext,
  data,
}) => {
  const loading = useContext(Context);
  useEffect(() => {
    hoverCircle();
  }, []);
  const { displayYear } = pageContext;
  return (
    !loading && (
      <>
        <SEO title={`${displayYear}年 | ニュース`} />
        <div className="page-wrap">
          <header className={pageHeaderCategory.page_header_category}>
            <h2>
              <small
                className={pageHeaderCategory.page_header_category__head_text}
              >
                お知らせ
              </small>
              <div className={pageHeaderCategory.page_header_category__text}>
                News
              </div>
            </h2>
            <p className={pageHeaderCategory.page_header_category__lead}>
              NRIネットコムに関するニュースをお届けします。
            </p>
          </header>
          <div className={`page-contents-area-01 ${styles.news_contents}`}>
            <div className="news_contents__list">
              {data.allMicrocmsNews.edges.map(({ node }) => (
                <>
                  <article className={`${styles.news_link} --borderBottom`}>
                    {node.pdf ? (
                      <Link
                        to={CDNReplace(node.pdf.url)}
                        target="_blank"
                        className={`${styles.news_link__wrap} js-hover-circle`}
                        data-circle-inner="arrow-right"
                      >
                        <UILabel text={Util.newsLabel(node.category[0])} />
                        <time
                          dateTime={Util.formatDate(new Date(node.publishedAt))}
                          className={styles.news_date}
                          style={{ marginLeft: `1.2rem` }}
                        >
                          {Util.formatDate(new Date(node.publishedAt))}
                        </time>
                        <p className={styles.news_link__title}>
                          {node.title}
                          <span className="tag-pdf">PDF</span>
                        </p>
                      </Link>
                    ) : (
                      <Link
                        to={`/news/${node.year}/${node.newsId}/`}
                        target="_blank"
                        className={`${styles.news_link__wrap} js-hover-circle`}
                        data-circle-inner="arrow-right"
                      >
                        <UILabel text={Util.newsLabel(node.category[0])} />
                        <time
                          dateTime={Util.formatDate(new Date(node.publishedAt))}
                          className={styles.news_date}
                          style={{ marginLeft: `1.2rem` }}
                        >
                          {Util.formatDate(new Date(node.publishedAt))}
                        </time>
                        <p className={styles.news_link__title}>{node.title}</p>
                      </Link>
                    )}
                  </article>
                </>
              ))}
            </div>
            <div className={styles.news_contents__side}>
              <div className={styles.news_side_box}>
                <NewsNavi CurrentYear={displayYear} />
              </div>
            </div>
          </div>
        </div>
        <UIBreadcrumb
          UpperItem={[[`News`, `/news`]]}
          CurrentItem={displayYear}
        />
      </>
    )
  );
};

export const query = graphql`
  query NewsArchive($periodStartDate: Date, $periodEndDate: Date) {
    allMicrocmsNews(
      sort: { fields: publishedAt, order: DESC }
      filter: {
        publishedAt: { gte: $periodStartDate, lt: $periodEndDate }
        newsId: { ne: "template" }
      }
    ) {
      edges {
        node {
          id
          category
          publishedAt(formatString: "YYYY/MM/DD")
          newsId
          title
          pdf {
            url
          }
          year: publishedAt(formatString: "YYYY")
        }
      }
    }
  }
`;

export default NewsArchive;
